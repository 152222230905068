<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "OwnerAskDropdown",
  props: {
    ask: {
      type: Object,
      default () {
        return null
      }
    },
    isTenant: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick (e) {
      console.log('click')
      console.log('click', e)
    },
    showIntercom () {
      Beacon('navigate', '/ask/')
      Beacon('open')
    },
  },
  computed: {
    dayLeft () {
      let now = new Date();
      let daysLeftDeparture = Math.ceil((this.ask.departUnix - now.getTime() / 1000) / (60 * 60 * 24))
      if (daysLeftDeparture > 0) {
        return daysLeftDeparture
      } else {
        return Math.ceil((this.ask.finUnix - now.getTime() / 1000) / (60 * 60 * 24))
      }
    },
    hoursAfterEndOfRental() {
      if (!this.ask || !this.ask.finUnix) return 0;
      let now = new Date();
      return Math.ceil((now.getTime() / 1000 - this.ask.finUnix) / (60 * 60));
    },
    askStatus () {
      if (this.ask) {
        let now = new Date();
        if (this.ask.canceled) {
          return 'canceled'
        } else if (this.ask.statutProprietaire === 1) {
          return 'to-confirm'
        } else if (this.ask.statutProprietaire === 2) {
          return 'waiting-payment'
        } else if (this.ask.statutLocataire === 3 && this.ask.statutProprietaire === 3) {
          let daysLeftDeparture = Math.ceil((this.ask.departUnix - now.getTime() / 1000) / (60 * 60 * 24))
          let daysLeftArrival = Math.ceil((this.ask.finUnix - now.getTime() / 1000) / (60 * 60 * 24))
          if (daysLeftDeparture === 0) {
            return 'today'
          } else if (daysLeftDeparture === 1) {
            return 'tomorrow'
          } else if (daysLeftDeparture > 1) {
            return 'day-left-departure'
          } else if (daysLeftArrival === 0) {
            return 'today-arrival'
          } else if (daysLeftArrival === 1) {
            return 'tomorrow-arrival'
          } else if (daysLeftArrival > 1) {
            return 'day-left-arrival'
          } else {
            return 'finish'
          }
        }
      }
      return 'finish'
    },
    elTagType () {
      if (this.askStatus === 'canceled') {
        return 'danger'
      } else if (this.askStatus === 'to-confirm') {
        return 'warning'
      } else if (this.askStatus === 'waiting-payment') {
        return 'info'
      } else if (this.askStatus === 'today') {
        return 'success'
      } else if (this.askStatus === 'tomorrow') {
        return 'warning'
      } else if (this.askStatus === 'day-left-departure') {
        return ''
      } else if (this.askStatus === 'today-arrival') {
        return 'success'
      } else if (this.askStatus === 'tomorrow-arrival') {
        return 'warning'
      } else if (this.askStatus === 'day-left-arrival') {
        return ''
      } else if (this.askStatus === 'finish') {
        return 'success'
      }
      return ''
    },
    hasFeesInvoice () {
      return this.ask.feesInvoice
    },
    hasOwnerInvoice () {
      return this.ask.ownerInvoice
    },
    hasAnyInvoice() {
      return this.ask.ownerInvoice || this.ask.feesInvoice
    },
    canDeclareSinister () {
      // Uniquement pour le loueur quand la location a une date de paidat, n'est pas canceled
      // et au maximum 48 heures après la date de fin de location
      return !this.isTenant && // Uniquement le loueur
             this.ask.paidAtUnix && // A une date de paiement
             !this.ask.canceled && // Pas annulée
             this.hoursAfterEndOfRental >= 0 && // La location est terminée
             this.hoursAfterEndOfRental <= 48; // Maximum 48h après la fin
    },
    showContract () {
      // Accessible aux locataires et loueurs si payée, non annulée, et max 48h après fin
      return this.ask.paidAtUnix && // A été payée
             !this.ask.canceled && // Pas annulée
             this.hoursAfterEndOfRental <= 48; // Max 48h après la fin
    }
  }
})
</script>

<template>
  <el-dropdown-menu @visible-change="handleClick" trigger="click">
    <el-dropdown-item v-if="ask.chatRoomId">
      <a class="dropdown-item" :href="'/user/messagerie/' + ask.chatRoomId">
        {{ $t('owner-rental.dropdown.chat') }}
      </a>
    </el-dropdown-item>
    <el-dropdown-item v-if="canDeclareSinister">
      <a class="dropdown-item" :href="ask.sinisterLink">
        {{ $t('owner-rental.dropdown.sinister') }}
      </a>
    </el-dropdown-item>
    <el-dropdown-item v-if="showContract">
      <a class="dropdown-item" :href="'/user/download-pdf-contrat-location/' + ask.id">
        {{ $t('owner-rental.dropdown.contract') }}
      </a>
    </el-dropdown-item>
    <el-dropdown-item v-if="isTenant && hasAnyInvoice">
      <a class="dropdown-item" :href="'/user/download-pdf-facture-locataire-location/' + ask.id">
        {{ $t('owner-rental.dropdown.invoice') }}
      </a>
    </el-dropdown-item>
    <el-dropdown-item v-if="!isTenant && hasOwnerInvoice">
      <a class="dropdown-item" :href="'/user/download-pdf-facture-locataire-location/' + ask.id">
        {{ $t('owner-rental.dropdown.invoice') }}
      </a>
    </el-dropdown-item>
    <el-dropdown-item v-if="!isTenant && hasFeesInvoice">
      <a class="dropdown-item" :href="'/user/download-pdf-facture-frais-proprietaire-location/' + ask.id">
        {{ $t('owner-rental.dropdown.invoice-fees') }}
      </a>
    </el-dropdown-item>
    <el-dropdown-item v-if="ask.id">
      <a class="dropdown-item" target="_blank" :href="'/user/location/' + ask.id">
        {{ $t('owner-rental.dropdown.show') }}
      </a>
    </el-dropdown-item>
    <el-dropdown-item>
      <a class="dropdown-item" href="#" @click="showIntercom">
        {{ $t('owner-rental.dropdown.help') }}
      </a>
    </el-dropdown-item>
  </el-dropdown-menu>
</template>

<style scoped>

</style>