<template>
    <div class="documentaire-step-left-main">
        <div :class="checkMark">
            <h3 class="multisteps-form__title mb-30">{{$t('infos.material.label')}}</h3>
            <div v-for="category in sousCategories">
                <h3 class="cat-title">{{category[1]}}</h3>
                <!-- {% set i = 1 %} -->
                <div v-for="panierAnnonce in paniersAnnonce" >
                    <div v-if="category[1] == panierAnnonce.annonces.souscategorieannonce.nom">
                        <div ref="refuseValideZo" :class="refuseValideZo(panierAnnonce)" style="min-height: 110px;">
                            <div class="img-section">
                                <label v-if="stepOne">
                                    <input v-if="panierAnnonce.valide == true" id="annonces" :checked="checked == true" class="checkboxAjax annoncesCheckbox" type="checkbox" :value="panierAnnonce.annonces.id" @change="onChangePa($event, panierAnnonce.annonces.id)">
                                    <input v-if="panierAnnonce.valide == false" id="annonces" :checked="checked == false" class="checkboxAjax annoncesCheckbox" type="checkbox" :value="panierAnnonce.annonces.id" @change="onChangePa($event, panierAnnonce.annonces.id)">
                                    <span class="box"></span>
                                </label>
                                <span class="box"></span>
                                <a v-if="annonceHasImage(panierAnnonce)" href="#"><img :src="panierAnnonce.annonces.cover.path" alt=""></a>
                                <a v-if="!annonceHasImage(panierAnnonce)" href="#"><img :src="asset('img/sans-photo.jpg')" alt=""></a>
                            </div>
                            <div class="info">
                                <h3><a :href="path('lighty_share_user_detail_annonce', {'id' : panierAnnonce.annonces.id})">{{ annnonceTitle(panierAnnonce) }}</a> 
                                <span v-if="DL.id > 31909"> 
                                    {{ panierAnnonce.prix_location_ht }}€ <em>HT</em>
                                    <i v-if="materialRefused(panierAnnonce) && locationAccepted">
                                        {{$t('infos.material.refuse')}}
                                    </i>
                                    <i v-if="!materialRefused(panierAnnonce) && locationAccepted">
                                        {{$t('infos.material.valid')}}
                                    </i>
                                </span>
                                </h3>
                                <h5 v-if="panierAnnonce.annonces.detailsannonce.length"><a data-toggle="modal" href="#" data-target="#modal-details-{{panierAnnonce.annonces.id}}" @click="showModalEquipment(panierAnnonce.annonces.detailsannonce)">{{$t('infos.material.equipment', {length: panierAnnonce.annonces.detailsannonce.length })}}</a></h5>
                                <div class="qty-section">
                                    <div class="qty-dropdown">
                                        <label>{{$t('infos.material.quantity')}} <strong>{{ panierAnnonce.quantity }}</strong></label>
                                    </div>
                                </div>
                                <div v-if="panierAnnonce && panierAnnonce.annonces && panierAnnonce.annonces.bundle && panierAnnonce.annonces.bundle.bundleItems" >
                                  <div v-for="bundleItem in panierAnnonce.annonces.bundle.bundleItems" :href="path('lighty_share_user_detail_annonce',{ 'id': bundleItem.annonceId})" target="_blank" class="bundle-item-card" >
                                    <div class="img">
                                      <img :src="bundleItem.cover" alt="">
                                    </div>
                                    <a :href="path('lighty_share_user_detail_annonce',{ 'id': bundleItem.annonceId})" class="info">
                                      <div class="quantity">
                                        x {{ panierAnnonce.quantity * bundleItem.quantity }}
                                      </div>
                                      <div class="title">
                                        {{bundleItem.title}}
                                      </div>
                                    </a>
                                  </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- {% set i = i + 1 %}-->
                </div>
            </div>
            <div v-if="panierLivraisons">
                <div v-for="panierLivraison in panierLivraisons">
                    <h3 class="cat-title">{{$t('infos.delivery.label')}}</h3>
                    <div :class="refuseValide(panierLivraison)">
                        <div class="img-section">
                            <label v-if="stepOne">
                                <input v-if="panierLivraison.valide == true" id="livraison" :checked="checked == true" class="checkboxAjax" type="checkbox" data-ng-model="example.check" :value="panierLivraison.id" name="livraison" @change="onChangeSi($event)">
                                <input v-if="panierLivraison.valide == false" id="livraison" :checked="checked == false" class="checkboxAjax" type="checkbox" data-ng-model="example.check" :value="panierLivraison.id" name="livraison" @change="onChangeSi($event)">
                                <span class="box"></span>
                            </label>
                            <span class="box"></span>
                            <a href="#"><img :src="asset('img/new/location-livraison-lightyshare.jpg')" alt=""></a>
                        </div>
                        <div class="info">
                            <h3>
                                <a href="#">{{$t('infos.delivery.info', {max: plDistanceMax(panierLivraison)})}}</a> <span> {{ panierLivraison.montant_ht }}€ <em>HT</em>
                                    <i v-if="materialRefused(panierLivraison) && locationAccepted">
                                        {{$t('infos.material.refuse')}}
                                    </i>
                                    <i v-if="!materialRefused(panierLivraison) && locationAccepted">
                                        {{$t('infos.material.valid')}}
                                    </i>
                                </span>
                            </h3>
                            <br>
                        </div>
                        <div class="qty-section">
                            <div class="qty-dropdown">
                                <label>
                                    {{$t('infos.delivery.quantity')}}<strong>1</strong>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import LocationModalEquipment from "./LocationModalEquipment.vue";
import LocationModalCancel from "./LocationModalCancel.vue";
import axios from "axios";
import { uncheckAnnonce, checkAnnonce, uncheckLivraison, checkLivraison } from '../../services/location.js';

export default {
    name: 'LocationInfosMaterial',
    emits: ["dlUpdated", "showModalEquipment", "showModalCancel", "overlay"],
    data() {
        return {
            checked: true,
            locationUpdated: null
        }
    },
    components: {
        LocationModalEquipment,
        LocationModalCancel
    },
    props: {
        auth : {
            type: Object
        },
        DL : {
            type: Object
        },
        termined : {
            type: Boolean
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        checkMark() {
            return this.isOwner && this.DL.AcceptedAt === null && this.DL.CanceledAt === null && this.termined == false ? "documentaire-cart-items doc-cartitems-checkmark" : "documentaire-cart-items"
        },

        stepOne() {
            return this.isOwner && this.DL.AcceptedAt === null && this.DL.CanceledAt === null && this.termined == false ? true : false
        },
        locationAccepted() {
            return this.DL.AcceptedAt !== null ? true : false 
        },
        sousCategories() {
          if (this.$symfony.sousCategories) {
            return this.$symfony.sousCategories
          }
          return this.paniersAnnonce.reduce((cat, pa) => {
            if (cat.findIndex((c) => pa.annonces.souscategorieannonce.nom === c[1]) === -1) {
              cat.push({1: pa.annonces.souscategorieannonce.nom})
            }
            return cat
          }, [])
        },
        paniersAnnonce () {
            return this.DL.panier.paniersAnnonce
        },
        panier () {
            return this.DL.panier
        },
        panierLivraisons () {
            return this.panier.service_item
        },
        annoncesValide() {
            return this.paniersAnnonce.filter(panierAnnonce => panierAnnonce.valide == true).length
        },
    },
    watch: {
        locationUpdated: {
            handler(newlocationUpdated, oldlocationUpdated) {
                console.log(this.annoncesValide)
                this.$emit('overlay', false);
            },
            deep: true,
        }
    },
    methods: {
        annonceHasImage(pa) {
            return pa && pa.annonces && pa.annonces.cover && pa.annonces.cover.path && pa.annonces.cover.path.length ? true : false
        },
        annnonceTitle(pa) {
            return pa && pa.annonces && pa.annonces.title && pa.annonces.title.length > 40 ? pa.annonces.title.slice(0, 40) + '...' : pa && pa.annonces && pa.annonces.title ? pa.annonces.title : ''
        },
        materialRefused (item) {
            return item.valide == false || item.valide == 0 ? true : false
        },
        refuseValideZo(item) {
            return item.valide == false || item.valide == 0 ? "cart-item-row refusee z-0" : "cart-item-row valide z-0"
        },
        refuseValide(item) {
            return item.valide == false || item.valide == 0 ? "cart-item-row refusee" : "cart-item-row valide"
        },
        plDistanceMax(pl) {
            return pl.serviceType == 'distanceMin' ? '5' : '15'
        },
        showModalEquipment(detailsEquipment) {
            this.$emit('showModalEquipment', [true, detailsEquipment]);
        },
        showModalCancel() {
            this.$emit('showModalCancel', true);
        },
        async onChangePa(e, annonceId) {
            var panierId = this.panier.id

            if (e.target.checked == true) {
                this.$emit('overlay', true);
                this.locationUpdated = await checkAnnonce(annonceId, panierId)
                this.$emit('dlUpdated', this.locationUpdated);
            } else if (e.target.checked == false) {
                if(this.annoncesValide > 1) {
                    this.$emit('overlay', true);
                    this.locationUpdated = await uncheckAnnonce(annonceId, panierId)
                    this.$emit('dlUpdated', this.locationUpdated);
                    console.log(this.locationUpdated)
                } else {
                    e.target.checked = true
                    this.showModalCancel()
                }
            }
        },
        async onChangeSi(e) {
            var panierId = this.panier.id
            if (e.target.checked == true) {
                this.$emit('overlay', true);
                this.locationUpdated = await checkLivraison(panierId)
                this.$emit('dlUpdated', this.locationUpdated);
            } else if (e.target.checked == false) {
                this.$emit('overlay', true);
                this.locationUpdated = await uncheckLivraison(panierId)
                this.$emit('dlUpdated', this.locationUpdated);
            }
        },
    },
}
</script>

