<script>
import AuthNavMenu from "~/components/AuthNavMenu.vue";
import PublicMenu from "~/components/PublicMenu.vue";
import SearchForm from "~/components/SearchForm.vue";

export default {
  name: "TopBar",
  components: {SearchForm, PublicMenu, AuthNavMenu}
}
</script>

<template>
  <header class="topHeader">
    <nav class="navbar navbar-expand-xl navbar-light">
      <a class="navbar-brand" href="/">
        <img :src="asset('img/new/logo-dark.png')" alt="LightyShare" />
      </a>
      <div class="navbar-nav mr-auto">
        <div class="tabNav steps-navigation">
          <ul style="pointer-events: none;">
            <li class="active"><a>{{ $t('valid-cart.events.step.1') }}</a></li>
            <li><a>{{ $t('valid-cart.events.step.2') }}</a></li>
            <li><a>{{ $t('valid-cart.events.step.3') }}</a></li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<style scoped>
/* Masquer la navigation par étapes sur mobile */
@media (max-width: 767px) {
  .steps-navigation {
    display: none !important;
  }
}
</style>