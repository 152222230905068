<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

export default {
  name: "DateRangePicker",
  emits: ['update:modelValue'],
  components: {
    VueDatePicker
  },
  props: {
    modelValue: {
      type: [String, Array],
      default: ''
      // required: true
    },
    format: {
      type: String,
      default: 'dd/MM/yyyy'
    }
  },
  methods: {
    apply () {
      console.log('apply')
      this.$refs.datepicker.selectDate()
      // this.$emit('update:modelValue', this.modelValue)
    }
  }
}
</script>

<template>
  <VueDatePicker
      ref="datepicker"
      :modelValue="modelValue"
      :format="format"
      @update:modelValue="$emit('update:modelValue', $event)"
      hide-input-icon
      range
      multi-calendars
      :enable-time-picker="false"
      menu-class-name="lightyshare_calendar"
      :locale="'fr'"
      :action-row="{ showSelect: false, showCancel: false, showNow: false, showPreview: false }"
  >
    <template #day="{ day }">
      <div class="day">{{day}}</div>
      <div class="after-day"></div>
    </template>
    <template #action-extra>
      <div class="daterangepicker-footer" >
        <span class='drp-custom-text'>{{ $t('valid-cart.cart-data.custom-text') }}</span>
        <button @click="apply" class="applyBtn btn btn-sm btn-primary" type="button">Valider</button>
      </div>
    </template>
  </VueDatePicker>
</template>

<style scoped>

</style>