<template>
  <div class="boutique-header section-dashboard">
    <h3 class="parameters-title">{{ $t('shop.dashboard.title', { name: capitalize(user && user.prenom ? user.prenom : auth && auth.prenom ? auth.prenom : '')}) }}</h3>
    <h4 class="parameters-subtitle">{{ $t('shop.dashboard.subtitle') }}</h4>
  </div>
  <div class="section-dashboard section-statistics" v-if="statistics.length">
    <span class="form-section-title">{{ $t('shop.dashboard.statistics.title')}}</span>
    <p class="section-description">{{ $t('shop.dashboard.statistics.description')}}</p>
    <el-scrollbar class="outside-scrollbar remove-container-padding">
      <div class="statistics-grid">
        <statistic v-for="statistic in statistics" :statistic="statistic"></statistic>
      </div>
    </el-scrollbar>
  </div>
  <div class="section-dashboard section-important-actions" v-if="importantAsksWaiting.length">
    <span class="form-section-title">{{ $t('shop.dashboard.important-actions.title')}}</span>
    <p class="section-description">{{ $t('shop.dashboard.important-actions.description')}}</p>
    <el-scrollbar class="remove-container-padding ">
      <div class="important-actions-grid">
  <!--    <CartCard></CartCard>-->
        <cart-card v-for="ask in importantAsksWaiting" :ask="ask" :cart="ask.panier" :user-type="'locataire'" class="with-absolute-buttons">
          <template #footer>
            <div class="ask-actions">
  <!--            <button @click="goToAsk(ask)" class="ask-action action-card-left">{{ $t('shop.dashboard.important-actions.ask-actions.see-more') }}</button>-->
              <router-link :to="`/shop/reservation/toutes/${ask.id}`" class="ask-action action-card-left">{{ $t('shop.dashboard.important-actions.ask-actions.see-more') }}</router-link>
              <el-button :loading="!!loadingConfirmation[ask.id]" class="ask-action action-card-right" @click="confirmAsk(ask)">{{ $t('shop.dashboard.important-actions.ask-actions.accept') }}</el-button>
            </div>
          </template>
        </cart-card>
      </div>
    </el-scrollbar>
<!--    <router-link class="more-button" :to="{name: 'waiting-asks'}">Tout afficher <span class="icomoon d-inline">&#xe912</span></router-link>-->
  </div>
  <div class="section-dashboard section-shop-actions">
    <span class="form-section-title">{{ $t('shop.dashboard.shop-actions.title')}}</span>
    <p class="section-description">{{ $t('shop.dashboard.shop-actions.description')}}</p>
    <div class="shop-actions-grid">
      <router-link v-for="action in shopActions" :to="action.to">
        <shop-action :action="action"></shop-action>
      </router-link>
    </div>
  </div>
  <div class="section-dashboard section-asks-shortcut">
    <span class="form-section-title">{{ $t('shop.dashboard.asks.title')}}</span>
    <p class="section-description">{{ $t('shop.dashboard.asks.description')}}</p>
    <AskListShortcut :asks-count="asksCount"></AskListShortcut>

  </div>
</template>
<script>
  import BarChart from "./BarChart.vue"
  import SmallReservationList from "./parameters/smallReservationList.vue";
  import Auth from "~/modules/auth/services/Auth.js";
  import ImportantActions from "~/modules/dashboard/components/ImportantActions.vue";
  import LoadContent from "~/mixins/loadContent.js";
  import Ads from "~/modules/ad/services/Ads.js";
  import Statistic from "~/modules/dashboard/components/Statistic.vue";
  import CartCard from "~/modules/cart/components/CartCard.vue";
  import ShopAction from "~/modules/dashboard/components/ShopAction.vue";
  import Rental from "~/modules/ask/services/Rental.js";
  import AskListShortcut from "~/modules/ask/components/AskListShortcut.vue";
  import { ElNotification } from 'element-plus'

  export default {
    name: "ShopDashboard",
    mixins: [LoadContent],
    beforeRouteEnter(to, from, next) {
      next((vm) => {
        vm.beforeDataFetch()
      })
    },
    components: {
      AskListShortcut,
      ShopAction,
      CartCard,
      Statistic,
      ImportantActions,
      BarChart,
      SmallReservationList
    },
    data () {
      return {
        loadingConfirmation: {},
        rentalWaiting: null,
        rentalValidated: null,
        tauxReponses: null,
        timeResponse: null,
        user: null,
        statisticLocationCharts: null
      }
    },
    computed: {
      asksCount () {
        if (this.response && this.response.counts) {
          return this.response.counts
        }
        return []
      },
      Model () {
        return Rental
      },
      fetchUrl () {
        return '/api/user/dashboard'
      },
      loadUrl () {
        return 'api/user/dashboard'
      },
      auth () {
        return Auth.query().first()
      },
      importantAsksWaiting () {
        let query = Rental.query().with('locataire.ImageProfil')
        let now = new Date();
        now.setHours(0,0,0,0);
        return query.where((ask) => {
          return (ask.statutLocataire === 1 && ask.statutProprietaire === 1 && ask.departUnix >= now.getTime() / 1000 && !ask.canceled)
        }).get()
      },
      statistics () {
        if (this.response && this.response.statistics) {
          return Object.keys(this.response.statistics).map((stat) => {
            let value = this.response.statistics[stat].value
            if (this.response.statistics[stat].unit === 'eur') {
              value = this.$currencyFormat.format(value)
            }
            return {
              label: this.response.statistics[stat].label,
              value,
            }
          })
        }
        return []
      },
      shopActions () {
        let shopActions = [{
          action: this.$t('shop.dashboard.shop-actions.add-product.title'),
          description: this.$t('shop.dashboard.shop-actions.add-product.description'),
          img: '/img/icons/camera-plus.png',
          to: { name: 'add-product' }
          // img: '/img/icons/package-plus.png',
        }]
        if (this.response && this.response.countAds > 1) {
          shopActions.push({
            action: this.$t('shop.dashboard.shop-actions.add-bundle.title'),
            description: this.$t('shop.dashboard.shop-actions.add-bundle.description'),
            img: '/img/icons/package-plus.png',
            to: { name: 'add-bundle' }
          })
        }
        return shopActions
      },
      rating () {
        return this.user && this.user.notationAverage ? this.user.notationAverage : 0
      },
      ratingClass () {
        if (this.rating >= 4) {
          return 'green'
        }
        if (this.rating >= 2.5) {
          return 'yellow'
        }
        return 'red'
      },
      displayTauxResponse () {
        return Math.round(this.tauxReponses)
      },
      tauxResponseClass () {
        if (this.displayTauxResponse > 75) {
          return 'green'
        }
        if (this.displayTauxResponse > 50) {
          return 'yellow'
        }
        return 'red'
      },
      displayTimeResponse () {
        if (this.timeResponse === null) {
          return '-'
        }
        if (this.timeResponse <= 3600) {
          return "Dans l'heure"
        }
        if (this.timeResponse <= (3600 * 24)) {
          let time = Math.round(this.timeResponse / 3600)
          if (time === 1) {
            time += ' heure'
          } else {
            time += ' heures'
          }
          return time
        }
        let time = Math.round(this.timeResponse / (3600 * 24))
        if (time === 1) {
          return time + ' jour'
        }
        return time + ' jours'
      },
      timeResponseClass () {
        if (this.timeResponse === null) {
          return 'green'
        }
        if (this.timeResponse <= 3600) {
          return 'yellow'
        }
        if (this.timeResponse <= (3600 * 24)) {
          return 'red'
        }
        return 'red'
      },
      approveRentalRate () {
        if (this.user && this.user.acceptedLocationsRate) {
          return Math.round(this.user.acceptedLocationsRate)
        } else {
          return '-'
        }
      },
      approveRentalRateClass () {
        if (this.user && this.user.acceptedLocationsRate) {
          if (this.user.acceptedLocationsRate > 75) {
            return 'green'
          }
          if (this.user.acceptedLocationsRate > 50) {
            return 'yellow'
          }
          return 'red'
        } else {
          return 'green'
        }
      },
      doneRentalRate () {
        if (this.user && this.user.totalLocations > 0) {
          return Math.round(((this.user.nbRentalsAsOwnerDone / this.user.totalLocations) * 100))
        } else {
          return '-'
        }
      },
      doneRentalRateClass () {
          if (this.doneRentalRate > 75) {
            return 'green'
          }
          if (this.doneRentalRate > 50) {
            return 'yellow'
          }
          return 'red'
      },
      receivedRental () {
        return this.user && this.user.totalLocations ? this.user.totalLocations : '-'
      },
      receivedRentalClass () {
        return this.user && this.user.totalLocations ? 'green' : 'yellow'
      },
      chartDataset () {
        if (this.statisticLocationCharts === null) {
          return [
              [0, 0, 0, 0, 0, 0],
              [0, 0, 0, 0, 0, 0]
          ]
        } else {
          return [
              this.statisticLocationCharts.map((data) => data.rental_reveived),
              this.statisticLocationCharts.map((data) => data.rental_done)
          ]
        }
      }
    },
    methods: {
      capitalize(str) {
        if (!str) return ''
        return str.split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ')
      },
      goToAsk (ask) {
        // window.location.href = this.path('step-locations', {})
      },
      async confirmAsk (ask) {
        console.log('confirmAsk')
        console.log(ask)
        this.loadingConfirmation[ask.id] = true
        try {
          let call = await Rental.api().get('/api/user/location-accept/' + ask.id)
            console.log('call')
            console.log(call)
          if (call && call.response && call.response.data) {
            if (call.response.data.success) {
              ElNotification({
                title: 'Location acceptée',
                message: call.response.data.message,
                type: 'success',
              })
            } else {
              ElNotification({
                title: 'Error',
                message: call.response.data.message,
                type: 'error',
              })
            }
          }

        } catch (e) {
          if (e && e.name && e.message) {
            ElNotification({
              title: 'Error :' + e.name,
              message: e.message,
              type: 'error',
            })
            throw new Error(e)
          }
        }
        this.loadingConfirmation[ask.id] = false
      },
      afterFetchSuccess (response) {
        this.saveResponseOtherData(response)
      },
      saveResponseOtherData (response) {
        if (response && response.response && response.response.data) {
          // if (response.response.data.availableCartQuantity) {
          //   this.response.availableCartQuantity = response.response.data.availableCartQuantity
          //   this.availableCartQuantity = response.response.data.availableCartQuantity
          // }
        }
      },
      // handleError (e) {
      //   console.error(e)
      // },
      // async loadDashboard () {
      //   const dashboardContent = await dashboard()
      //   if (dashboardContent.data && dashboardContent.data.statistics_locations_charts) {
      //     this.statisticLocationCharts = dashboardContent.data.statistics_locations_charts
      //   }
      //   if (dashboardContent.data && dashboardContent.data.user) {
      //     this.user = dashboardContent.data.user
      //   }
      //   if (dashboardContent.data && dashboardContent.data.taux_response) {
      //     this.tauxReponses = dashboardContent.data.taux_response
      //   }
      //   if (dashboardContent.data && dashboardContent.data.time_response) {
      //     this.timeResponse = dashboardContent.data.time_response
      //   }
      //   if (dashboardContent.data && dashboardContent.data.rental_waiting) {
      //     this.rentalWaiting = dashboardContent.data.rental_waiting
      //   }
      //   if (dashboardContent.data && dashboardContent.data.rental_validated) {
      //     this.rentalValidated = dashboardContent.data.rental_validated
      //   }
      // }
    }
  }
</script>
<style>

</style>