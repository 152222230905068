import { Model } from "@vuex-orm/core"
import User from "~/modules/user/services/User.js";
import CartItem from "~/modules/cart/services/CartItem.js";

export default class Cart extends Model {
  static entity = 'cart'

  static apiConfig = {
    headers: { 'X-Requested-With': 'XMLHttpRequest' },
    baseURL: '/api',
    dataKey: 'cart',
    actions: {
      fetch: {
        method: 'get',
        url: 'bundle'
      }
    }
  }
  static fields () {
    return {
      id: this.attr(null),
      user_id: this.attr(null),
      user: this.belongsTo(User, 'user_id'),
      proprietaire_annonce: this.attr({}),
      nbrJourUtilisation: this.attr(null),
      startAt: this.attr(null),
      endAt: this.attr(null),
      franchise: this.attr(null),
      caution: this.attr(null),
      valeurTotalMateriel: this.attr(null),
      serviceTotalHt: this.attr(null),
      serviceTotalTtc: this.attr(null),
      fraisService: this.attr(null),
      fraisServiceHT: this.attr(null),
      fraisServiceTTC: this.attr(null),
      prixTotalTtc: this.attr(null),
      prixTotalHt: this.attr(null),
      prixHT: this.attr(null),
      prixNormal: this.attr(null),
      assuranceHt: this.attr(null),
      cartItems: this.hasMany(CartItem, 'cart_id', 'id'),
      serviceItem: this.attr([], value => {
        return Array.isArray(value) ? value.map(item => ({
          id: item.id || null,
          service: item.service || null,
          valide: item.valide || false,
          montant_ht: item.montant_ht || null,
          serviceType: item.serviceType || null,
          setting: item.setting || null
        })) : []
      })
    }
  }
}