<script>
import LoadContent from "~/mixins/loadContent.js";
import AskModelTable from "~/modules/ad/components/AskModelTable.vue";
import DemandeAjoutModele from "~/modules/ad/services/DemandeAjoutModele.js";
import AutoMatchModels from "~/modules/admin/components/AutoMatchModels.vue";
import axios from 'axios';

export default {
  name: "AdminAskNewModel",
  components: {
    AskModelTable,
    AutoMatchModels
  },
  fetchUrl: 'admin/asks-modele-list',
  mixins: [LoadContent],
  data() {
    return {
      suggestions: [],
      automaticMatches: [],
      isValidating: null // Pour gérer l'état de chargement du bouton
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    Model() {
      return DemandeAjoutModele
    },
    filteredSuggestions() {
      // Grouper les suggestions par demande
      const groupedSuggestions = this.suggestions.map(item => {
        const sortedSuggestions = [...item.suggestions].sort((a, b) => b.score - a.score);
        return {
          ...item,
          suggestions: sortedSuggestions.filter((s, index) => {
            const score = s.score * 100;
            if (score >= 95) {
              return index < 2; // Garder seulement les 2 meilleures suggestions
            }
            if (score >= 50 && score < 95) {
              return index < 5; // Garder les 5 meilleures suggestions
            }
            return false; // Ignorer les suggestions < 50%
          })
        };
      });
      
      // Ne garder que les demandes qui ont encore des suggestions après filtrage
      return groupedSuggestions.filter(item => item.suggestions.length > 0);
    }
  },
  methods: {
    preQuery (query) {
      query.with(['user.ImageProfil', 'user.infoSociete'])
    },
    handleNewClick () {
      console.log('handle new click')
    },
    onAutoMatchComplete(results) {
      if (results) {
        this.suggestions = results.suggestions || [];
        this.automaticMatches = results.automaticMatches || [];
        // Forcer la réinitialisation du composant AutoMatchModels
        this.$nextTick(() => {
          const autoMatchComponent = this.$refs.autoMatch;
          if (autoMatchComponent) {
            autoMatchComponent.resetState();
          }
        });
      }
      // Rafraîchir la liste après le traitement automatique
      this.beforeDataFetch();
    },
    async validateSuggestion(demandeId, suggestion) {
      const validationId = demandeId + '-' + suggestion.marque + suggestion.modele;
      this.isValidating = validationId;
      
      try {
        const response = await axios.post('/admin/validate-suggestion', {
          demandeId,
          suggestion
        });
        
        if (response.data.success) {
          // Retirer la suggestion validée de la liste
          this.suggestions = this.suggestions.filter(s => s.demande_id !== demandeId);
          
          // Rafraîchir la liste principale
          this.beforeDataFetch();
          
          this.$notify({
            title: 'Succès',
            message: 'Suggestion validée avec succès',
            type: 'success'
          });
        }
      } catch (error) {
        console.error('Erreur lors de la validation:', error);
        this.$notify({
          title: 'Erreur',
          message: 'Impossible de valider la suggestion',
          type: 'error'
        });
      } finally {
        this.isValidating = null;
      }
    }
  }
}
</script>

<template>
  <el-card class="p-0">
    <div class="table-filter-container">
      <div class="d-flex justify-content-between align-items-center">
        <h3 class="subTitle mb-0">Demande d'ajout <span v-if="paginationQuery.count" class="countLabel">{{paginationQuery.count}} Demandes d'ajouts</span></h3>
      </div>
    </div>
    <AskModelTable :ask-models="modelList">
      <template #empty>
        <div class="empty-background">
          <div v-if="isLoadingContent" class="loading-content">
            <div class="cv-spinner">
              <span class="spinner"></span>
            </div>
            <div class="empty-background-content-title">
              {{ $t('shop.products.product-list.loading')}}
            </div>
          </div>
          <div v-else class="empty-content">
            <div>
              <div class="non-clickable-button icomoon">
                &#xe979
              </div>
              <div class="empty-background-content-title">
                Félicitation !
              </div>
              <div class="empty-background-content-description">
                Aucune de demande d'ajout de modèle a traiter
              </div>
            </div>
          </div>
        </div>
      </template>
    </AskModelTable>
    <div class="pagination-container" v-if="paginationQuery && paginationQuery.count">
      <el-pagination :current-page="currentPage" hide-on-single-page background layout="prev, pager, next" :page-size="10" :total="paginationQuery.count" @current-change="changePage"/>
    </div>

    <div class="mt-4">
      <div class="d-flex flex-column">
        <div class="mb-3">
          <AutoMatchModels ref="autoMatch" @complete="onAutoMatchComplete" />
        </div>

        <!-- Suggestions pour validation manuelle -->
        <div v-if="filteredSuggestions.length" class="suggestions-section">
          <h3>Suggestions de correspondance ({{ filteredSuggestions.length }})</h3>
          <div class="info-text">Correspondances nécessitant une validation manuelle</div>
          <el-table :data="filteredSuggestions" style="width: 100%" border>
            <el-table-column label="Demande" min-width="200">
              <template #default="{ row }">
                <div class="model-cell">
                  <div class="model-name">{{ row.demande.marque }} {{ row.demande.modele }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Suggestions" min-width="400">
              <template #default="{ row }">
                <div class="suggestions-list">
                  <div v-for="(suggestion, index) in row.suggestions" :key="index" class="suggestion-item">
                    <div class="suggestion-content">
                      <span class="suggestion-name">{{ suggestion.marque }} {{ suggestion.modele }}</span>
                      <div class="score-cell">
                        <el-progress 
                          :percentage="Math.round(suggestion.score * 100)"
                          :status="suggestion.score >= 0.8 ? 'warning' : 'exception'"
                        />
                        <span class="score-text">{{ Math.round(suggestion.score * 100) }}%</span>
                      </div>
                    </div>
                    <el-button 
                      type="success" 
                      size="small"
                      @click="validateSuggestion(row.demande_id, suggestion)"
                      :loading="isValidating === row.demande_id + '-' + index">
                      Valider
                    </el-button>
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </el-card>
</template>

<style scoped>
.table-filter-container {
  padding: 1rem;
}

.suggestions-section {
  margin-top: 20px;
}

.info-text {
  color: #606266;
  font-size: 14px;
  margin-bottom: 12px;
}

.model-cell {
  display: flex;
  align-items: center;
  gap: 10px;
}

.model-name {
  font-weight: 500;
}

.score-cell {
  display: flex;
  align-items: center;
  gap: 8px;
}

.score-text {
  font-size: 13px;
  color: #606266;
  min-width: 40px;
}

.suggestions-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.suggestion-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px;
  background-color: #f5f7fa;
  border-radius: 4px;
}

.suggestion-content {
  display: flex;
  align-items: center;
  gap: 20px;
  flex: 1;
}

.suggestion-name {
  font-weight: 500;
  min-width: 200px;
}
</style>