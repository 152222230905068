<template>
    <div class="sticky-bottom-button button-area">
<!--      <el-button @click="finalizeAsk">Finaliser la location</el-button>-->
<!--      <el-button @click="resetAsk">reset la location</el-button>-->
        <div v-if="isOwner && stepOne">
            <form id="form-accept" :action="path('accept_location_proprietaire_panier')" method="post">
                <div class="choice">
                    <button type = "submit" id="valider" class="btn btn-primary desktop-only">
                        {{$t('box.button.accept')}}
                    </button>
                    <a class="btn btn-outline-primary desktop-only" data-toggle="modal" data-target="#cancel" href="#" @click="showModalCancel">
                        {{$t('box.button.refuse')}}
                    </a>
                    <a class="btn btn-outline-primary mobile-only" data-toggle="modal" data-target="#cancel" href="#" @click="showModalCancel">
                        {{$t('box.button.refuse')}}
                    </a>
                    <button type = "submit" id="valider" class="btn btn-primary mobile-only" style="width: 100%;">
                        {{$t('box.button.accept')}}
                    </button>
                    <input id="dId" type="hidden" name="demandeId" :value="DL.id">
                </div>
            </form>
        </div>
        <a v-if="showButtonChatroom" target="__blank" class="btn btn-primary btn-blue js-btn-next" :href="path('lighty_share_user_messagerie',{'roomopen': DL.chatLocation[0].chatroom.id })">
            {{$t('box.button.chatroom')}}
        </a>
        <a v-if="isOwner && stepThree && userHasNumber(proprietaire) && nothingLeftToPay" href="" class="contacter-btn">
        <img :src="asset('img/svg/call-icon.svg')">
        <span> +{{ locataire.telephone.telephone }} </span>
        </a>
        <a v-if="isTenant && stepThree && userHasNumber(locataire) && nothingLeftToPay" href="" class="contacter-btn">
        <img :src="asset('img/svg/call-icon.svg')">
        <span> +{{ proprietaire.telephone.telephone }} </span>
        </a>
        <div v-if="isTenant">
            <div v-if="stepTwo">
                <div v-if="bankWire && bankWire.createdAt && bankWire.createdAt !== null">
                    <input disabled type="submit" class="btn btn-primary disabled" style="background: #F4F4F4; color: #A3A3A3; " value="En attente de virement">
                    <!-- <center> -->
                        <a style="font-weight:700; text-decoration: underline !important; display:inline-block; padding-top: 1em;" class="link" :href="path('rental_payment',{'id': DL.id})">
                            {{$t('box.button.creditCard')}}
                        </a>
                    <!--</center> -->
                </div>
                <div v-if="(bankWire && bankWire.createdAt && bankWire.createdAt === null) || bankWire === null && !stepLocationUpdatingChoice">
                    <a id="valider" class="btn btn-primary" @click="goRentalPayment" :href="path('rental_payment',{'id': DL.id})">
                        {{$t('box.button.payment')}}
                    </a>
                </div>
            </div>
            <div v-if="stepLocationUpdatingPayment && !stepTwo">
                <a id="valider" class="btn btn-primary" @click="goRentalPayment" :href="path('rental_payment',{'id': DL.id})">
                    {{$t('box.button.payment')}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import LocationModalCancel from "./LocationModalCancel.vue";
import LocationBoxUpdate from "./LocationBoxUpdate.vue";
import Rental from "~/modules/ask/services/Rental.js";

export default {
    name: 'LocationBoxButtons',
    emits: ["showModalCancel", "goRentalPayment"],
    components: {
        LocationModalCancel,
        LocationBoxUpdate
    },
    data () {
        return {
            noCertificationPopup: true,
        }
    },
    props: {
        auth : {
            type: Object
        },
        termined : {
            type: Boolean
        },
        DL : {
            type: Object
        },
    },
    computed: {
        locataire () {
            return this.DL.locataire
        },
        proprietaire () {
            return this.DL.proprietaire
        },
        bankWire() {
            return this.DL.bank_wire
        },
        isTenant() {
            return this.auth.id == this.locataire.id ? true : false
        },
        isOwner() {
            return this.auth.id == this.proprietaire.id ? true : false
        },
        stepIsLocationUpdating () {
            return this.DL.updateStatusLocation == 1 || this.DL.updateStatusLocation == 2 ? true : false 
        },
        stepLocationUpdatingChoice () {
            return this.DL.updateStatusLocation == 1 ? true : false
        },
        stepLocationUpdatingPayment () {
            return this.DL.updateStatusLocation == 2 ? true : false
        },
        stepOne () {
            return this.DL.AcceptedAt === null && this.DL.PaidAt === null && this.termined === false && this.DL.CanceledAt === null ? true : false
        },
        stepTwo () {
            return (this.DL.leftToPay > 0 && this.DL.AcceptedAt !== null && this.DL.CanceledAt === null) || (this.DL.AcceptedAt !== null &&
            // this.DL.leftToPay > 0 &&
            this.termined == false &&
            this.DL.CanceledAt === null) ? true : false
        },
        stepThree () {
            return this.DL.AcceptedAt !== null && this.DL.leftToPay === 0 && this.termined == false && this.DL.CanceledAt === null && this.stepIsLocationUpdating === false ? true : false
        },
        showButtonChatroom() {
            if(this.isTenant) {
                return (this.DL.AcceptedAt === null || this.DL.PaidAt !== null ) && (this.DL.CanceledAt === null && this.termined == false) && this.stepIsLocationUpdating == false ? true : false
            } else if (this.isOwner) {
                return (this.DL.AcceptedAt !== null || this.DL.PaidAt !== null ) && (this.DL.CanceledAt === null && this.termined == false)
            }
        },
        nothingLeftToPay() {
            return this.DL.leftToPay <= 0 ? true : false
        }
    }, 
    methods : {
      finalizeAsk () {
        Rental.api().get('/api/user/location/' + this.DL.id + '/finalize')
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error);
          });
      },
      resetAsk () {
        Rental.api().get('/api/user/location/' + this.DL.id + '/remove-unavailability')
          .then((response) => {
            console.log(response)
          })
          .catch((error) => {
            console.log(error);
          });
      },
      goRentalPayment ($event) {
        this.$emit('goRentalPayment', $event);
      },
      userHasNumber(user) {
          return user.telephone && user.telephone !== null ? true : false
      },
      showModalCancel() {
          this.$emit('showModalCancel', true);
      },
    }
}
</script>