<template>
  <el-sub-menu v-if="item.submenu && item.submenu.length" :index="item.key">
    <template #title>
      <span :class="item.class">
        <span class="icon-kit" v-html="item.icon"></span>
        <span>{{ item.label }}</span>
      </span>
    </template>
    <menu-item v-for="menu in item.submenu" :item="menu" :link-class="linkClass" @itemClick="$emit('item-click')"/>
  </el-sub-menu>
  
  <!-- Cas 1: Lien Vue Router standard (sans href) -->
  <el-menu-item v-else-if="item.to && !item.to.href" :index="item.key" :class="[item.class, { 'active-menu-item': isActiveRoute }]">
    <router-link :to="item.to" :class="linkClass">
      <span v-if="item.icon" class="icon-kit" v-html="item.icon"></span>
      {{ item.label }}
    </router-link>
  </el-menu-item>
  
  <!-- Cas 2: Lien direct avec href -->
  <el-menu-item v-else-if="item.to && item.to.href" :index="item.key" :class="[item.class, { 'active-menu-item': isDirectLinkActive }]">
    <a :href="item.to.href" :class="linkClass" @click="onClickDirectLink">
      <span v-if="item.icon" class="icon-kit" v-html="item.icon"></span>
      {{ item.label }}
    </a>
  </el-menu-item>
  
  <!-- Cas par défaut -->
  <el-menu-item v-else :index="item.key" :class="item.class" @click="$emit('item-click')">
    <span class="icon-kit" v-html="item.icon"></span>
    <span>{{ item.label }}</span>
  </el-menu-item>
</template>
<script>
  export default {
    name:"MenuItem",
    emits: ['item-click'],
    props: {
      item: {
        default () {
          return {}
        }
      },
      linkClass: {
        default: '',
        type: String
      }
    },
    computed: {
      // Vérification pour les routes Vue Router normales
      isActiveRoute() {
        if (!this.item.to || this.item.to.href) return false;
        
        // Debug pour voir les routes et noms impliqués
        if (this.item.label === 'Locations') {
          // console.log('Vérification route Locations:', {
          //   routeName: this.$route.name,
          //   routePath: this.$route.path,
          //   itemName: this.item.to.name,
          //   itemPath: this.item.to.path
          // });
        }
        
        // Vérification exacte du nom de route
        if (this.$route.name === this.item.to.name) {
          return true;
        }
        
        // Vérification du nom de route avec segments (tenant-asks vs tenant-asks-id)
        if (this.$route.name && this.item.to.name && this.$route.name.startsWith(this.item.to.name)) {
          return true;
        }

        // Vérification du chemin
        if (this.item.to.path && this.$route.path.startsWith(this.item.to.path)) {
          return true;
        }
        
        // Vérification spéciale pour tenant-asks et locataire/reservations
        if (this.item.to.name === 'tenant-asks' && this.$route.path.includes('/locataire/reservations')) {
          return true;
        }
        
        return false;
      },
      
      // Vérification pour les liens directs (href)
      isDirectLinkActive() {
        if (!this.item.to || !this.item.to.href) return false;
        
        // Si le lien est marqué comme externe, il ne devrait jamais être actif
        if (this.item.to.isExternal) return false;
        
        // Si le lien commence par http:// ou https://, c'est un lien externe
        if (this.item.to.href.startsWith('http')) return false;
        
        // Vérifier si le href correspond à l'URL actuelle (correspondance exacte uniquement)
        const currentPath = window.location.pathname;
        const isActive = currentPath === this.item.to.href;
        
        // console.log(`[MenuItem] ${this.item.label} - currentPath:`, currentPath, 'href:', this.item.to.href, 'isActive:', isActive);
        
        return isActive;
      }
    },
    methods: {
      onClickDirectLink(e) {
        // console.log('[MenuItem] Click sur lien direct:', this.item.label);
        
        // Si onClick est spécifié et est une méthode valide dans le parent
        if (this.item.onClick && typeof this.$parent[this.item.onClick] === 'function') {
          // console.log('[MenuItem] Exécution de la méthode onClick:', this.item.onClick);
          e.preventDefault();
          this.$parent[this.item.onClick]();
          return;
        }
        
        this.$emit('item-click');
        // La navigation se fera normalement par le navigateur
      },
      
      onClickItem(to) {
        // console.log('[MenuItem] Click sur item:', to);
        this.$emit('item-click')
        if (to.href) {
          window.location.href = to.href
        } else {
          // this.$router.push(to)
        }
      }
    },
    mounted() {
      // console.log('[MenuItem] Mounted -', this.item.label);
    }
  }
</script>
<style>
.active-menu-item a {
  color: #fc2249 !important;
}
</style>
