<script>
import AskCartItem from "~/modules/ask/components/AskCartItem.vue";
import ModalLayout from "~/layouts/ModalLayout.vue";
import Rental from "~/modules/ask/services/Rental";
import formHandler from "~/mixins/formHandler";

export default {
  name: "AskCart",
  components: {ModalLayout, AskCartItem},
  mixins: [formHandler],
  emits: ['update:modelValue', 'change'],
  props: {
    availableCartQuantity: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modifiable: {
      type: Boolean,
      default: false
    },
    ask: {
      type: Object,
      default: () => {
        return {}
      }
    },
    modelValue: Object
  },
  data () {
    return {
      equipmentModal: false,
      edit: null
    }
  },
  watch: {
    ask: {
      immediate: true,
      deep: true,
      handler (val) {
        if (val && !this.edit) {
          this.edit = JSON.parse(JSON.stringify(val))
        }
        if (val && this.edit) {
          let data = JSON.parse(JSON.stringify({
            ...this.edit,
            ...val
          }))
          this.edit = data
        }
      }
    }
  },
  methods: {
    syncEdit (data) {
      this.edit = JSON.parse(JSON.stringify(data))
    },
    updateItem (item) {
      if (this.$refs['askCartItem' + item.id]) {
        this.$refs['askCartItem' + item.id][0].updateItem(item)
      }
    },
    initEdit () {

    },
    async changeItem (item) {
      const AskAdIndex = this.edit.panier.paniersAnnonce.findIndex((cartItem) => {
        return cartItem && cartItem.id === item.id
      })
      
      const serviceIndex = this.edit.panier.service_item ? this.edit.panier.service_item.findIndex((serviceItem) => {
        return serviceItem && serviceItem.id === item.id
      }) : -1
      
      if (AskAdIndex !== -1) {
        const previousItem = { ...this.edit.panier.paniersAnnonce[AskAdIndex] }
        this.edit.panier.paniersAnnonce[AskAdIndex] = item
        this.updateLocalPrices()

        try {
          const response = await this.sendForm(true, {
            Cart: {
              cartItems: this.edit.panier.paniersAnnonce.map((i) => ({
                id: i.id,
                valide: i.valide,
                quantity: i.quantity
              })),
              save: true
            }
          }, `/api/user/panier-edit/${this.edit.panier.id}`, 'Cart')
          
          this.$emit('change', this.edit, item)
          this.$emit('update:modelValue', this.edit)
        } catch (error) {
          this.edit.panier.paniersAnnonce[AskAdIndex] = previousItem
          this.updateLocalPrices()
        }
      } else if (serviceIndex !== -1) {
        const previousItem = { ...this.edit.panier.service_item[serviceIndex] }
        this.edit.panier.service_item[serviceIndex] = item
        this.updateLocalPrices()
        
        try {
          const response = await this.sendForm(true, {
            Cart: {
              cartItems: this.edit.panier.paniersAnnonce.map((i) => ({
                id: i.id,
                valide: i.valide,
                quantity: i.quantity
              })),
              serviceItem: [item],
              save: true
            }
          }, `/api/user/panier-edit/${this.edit.panier.id}`, 'Cart')
          
          this.$emit('change', this.edit, item)
          this.$emit('update:modelValue', this.edit)
        } catch (error) {
          this.edit.panier.service_item[serviceIndex] = previousItem
          this.updateLocalPrices()
        }
      }
    },
    toogleAdActive (value, item) {
      const AskAdIndex = this.edit.panier.paniersAnnonce.findIndex((cartItem) => {
        return cartItem && cartItem.annonces && cartItem.annonces.id === item.annonces.id
      })
      if (AskAdIndex !== -1) {
        this.edit.panier.paniersAnnonce[AskAdIndex].valide = value
        this.$emit('change', this.edit)
      }
    },
    updateLocalPrices() {
      if (!this.edit || !this.edit.panier || !this.edit.panier.paniersAnnonce) {
        return;
      }

      const prixNormal = this.edit.panier.paniersAnnonce.reduce((total, item) => {
        return total + (parseFloat(item.prix_normal) || 0);
      }, 0);

      const prixLocationHT = this.edit.panier.paniersAnnonce.reduce((total, item) => {
        const isValid = item.valide === true || item.valide === 'true';
        if (isValid) {
          return total + (parseFloat(item.prix_location_ht) || 0);
        }
        return total;
      }, 0);

      this.edit.panier.prixNormal = prixNormal.toFixed(2);
      this.edit.panier.prix_location_ht = prixLocationHT.toFixed(2);
      this.edit.panier.prix_location_ttc = (prixLocationHT * 1.2).toFixed(2);
      
      const serviceHT = parseFloat(this.edit.panier.service_total_ht || 0);
      this.edit.panier.prix_total_ht = (prixLocationHT + serviceHT).toFixed(2);
      this.edit.panier.prix_total_ttc = ((prixLocationHT + serviceHT) * 1.2).toFixed(2);
    },
    async openEquipmentModal (equipment) {
      this.equipmentModal = equipment
    },
    async closeEquipmentModal () {
      this.equipmentModal = false
    },
  },
  computed: {
    Model () {
      return Rental
    },
    formSave () {
      return true
    },
    formKey () {
      return 'cartItem'
    },
    apiUrl () {
      return '/api/user/ask'
    },
    itemClasses () {
      if (this.modifiable) {
        return 'selectable'
      }
    },
    cart () {
      return this.ask && this.ask.panier ? this.ask.panier : null
    },
    cartItemsCategories () {
      if (this.ask && this.ask.panier && Array.isArray(this.ask.panier.paniersAnnonce)) {
        return this.ask.panier.paniersAnnonce.reduce((categorize, cartItem, key) => {
          let hasCategory = categorize.findIndex((category) => {
            return category && cartItem && cartItem.annonces && cartItem.annonces.souscategorieannonce && category.id === cartItem.annonces.souscategorieannonce.id
          })
          cartItem['key'] = key
          if (hasCategory === -1) {
            let id = null
            let name = null

            if (cartItem && cartItem.annonces && cartItem.annonces.souscategorieannonce) {
              id = cartItem.annonces.souscategorieannonce.id
              name = cartItem.annonces.souscategorieannonce.nom
            }
            let editCartItem = {}
            if (this.edit && this.edit.panier && this.edit.panier.paniersAnnonce && this.edit.panier.paniersAnnonce[key]) {
              editCartItem = this.edit.panier.paniersAnnonce[key]
            }
            categorize.push({
              id: id,
              name: name,
              items: [{...cartItem, ...editCartItem}]
            })
          } else {
            categorize[hasCategory].items.push(cartItem)
          }
          return categorize
        }, [])
      } else {
        return []
      }
    },
    getServiceIcon() {
      return (serviceName) => {
        switch(serviceName) {
          case 'livraison':
            return '/img/icons/location-livraison-lightyshare.jpg'
          default:
            return '/img/icons/service.svg'
        }
      }
    },
    getServiceLabel() {
      return (serviceName) => {
        switch(serviceName) {
          case 'livraison':
            return 'Service de livraison'
          default:
            return 'Service'
        }
      }
    },
    idInUrl() {
      return false
    }
  }
}
</script>

<template>
  <div>
    <h3 class="multisteps-form__title">Liste matériel</h3>
    <div v-for="category in cartItemsCategories">
      <div>
        <h4 class="category-title">{{ category.name }}</h4>
      </div>
      <div class="cart-items">
        <AskCartItem v-for="item in category.items" :key="item.key" :ref="'askCartItem' + item.id" :item="item" class="cart-item" :class="itemClasses" :selectable="modifiable" :ask="ask.panier.paniersAnnonce[item.key]" @changeItem="changeItem" @toogle-item="toogleAdActive" @show-equipments="openEquipmentModal" :availableItemQuantity="availableCartQuantity[item.annonces.id] ? availableCartQuantity[item.annonces.id] : {}">
        </AskCartItem>
      </div>
    </div>

    <!-- Ajout de la section des services -->
    <div v-if="cart && cart.service_item && cart.service_item.length > 0">
      <h4 class="category-title">Services additionnels</h4>
      <div class="cart-items">
        <div v-for="service in cart.service_item" :key="service.id" class="ask-cart-item-row" :class="{'unvalid-item': !service.valide}">
          <div v-if="modifiable" class="ask-cart-checkbox">
            <el-checkbox v-model="service.valide" @change="changeItem(service)"></el-checkbox>
          </div>
          <div class="cart-item-image">
            <img :src="getServiceIcon(service.service.name)" :alt="service.service.name">
          </div>
          <div class="cart-item-content">
            <div class="d-flex align-items-center justify-content-between w-100">
              <div>
                <h4 class="cart-item-title mb-0">
                  {{ getServiceLabel(service.service.name) }}
                  <span v-if="service.serviceType === 'distanceMin'">0-5km</span>
                  <span v-if="service.serviceType === 'distanceMax'">5-15km</span>
                  <span v-if="!service.valide" class="badge badge-danger ms-2">Indispo.</span>
                </h4>
              </div>
              <div class="d-flex align-items-center justify-content-end" style="min-width: 120px">
                <span>{{ $currencyFormat.format(service.montant_ht) }}</span> HT
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <Teleport to="body">
    <ModalLayout :show="equipmentModal" @close="closeEquipmentModal" modal-class="full-width-modal">
      <template #header>
        <h3><span class="icon-kit">&#xe925</span>Inclus dans l'annonce</h3>
      </template>
      <template #body>
        <el-scrollbar ref="scrollbarRef" max-height="80vh" class="remove-modal-padding">
          <div class="equipment-item-row">
            <div>Qté</div>
            <div>Equipement</div>
          </div>
          <div v-for="equipment in equipmentModal" class="equipment-item-row">
            <div>{{ equipment.quantity }}</div>
            <div>{{ equipment.nomEquipement }}</div>
          </div>
        </el-scrollbar>
      </template>
    </ModalLayout>
  </Teleport>
</template>

<style scoped>
.category-title {
  font-size: 16px;
  font-weight: 600;
  margin: 1.5rem 0 1rem;
  color: #333;
}

.cart-item {
  margin-bottom: 1rem;
}

.cart-item-title {
  font-size: 16px !important;
}
</style>