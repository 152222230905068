<!-- MessagerieButton.vue - Composant personnalisé pour le bouton Messagerie -->
<template>
  <el-button 
    type="primary"
    plain
    :size="size"
    :loading="loading"
    class="messagerie-button"
    :class="classes"
    style="width: 100%;"
    @click="handleClick">
    {{ label }}
  </el-button>
</template>

<script>
export default {
  name: 'MessagerieButton',
  props: {
    label: {
      type: String,
      default: 'Messagerie'
    },
    size: {
      type: String,
      default: 'large'
    },
    loading: Boolean,
    classes: String,
    chatRoomId: String,
    fullwidth: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    handleClick() {
      if (this.chatRoomId) {
        window.location.href = this.path('lighty_share_user_messagerie', {roomopen: this.chatRoomId});
      }
    },
    path(route, params) {
      // Méthode de compatibilité avec la fonction path() disponible dans les composants principaux
      // Cette implémentation est une version simplifiée qui construit l'URL directement
      if (route === 'lighty_share_user_messagerie') {
        return `/user/messagerie/${params.roomopen}`;
      }
      return '#';
    }
  }
}
</script>

<style scoped>
.messagerie-button {
  color: var(--el-color-white) !important;
}
.messagerie-button:hover,
.messagerie-button:focus,
.messagerie-button:active {
  color: var(--el-color-white) !important;
}
</style> 