<script>
import LoadContent from "~/mixins/loadContent.js";
import Cart from "~/modules/cart/services/Cart.js";
import CartCard from "~/modules/cart/components/CartCard.vue";

export default {
  name: "CartsList",
  mixins: [LoadContent],
  components: { CartCard },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.beforeDataFetch()
    })
  },
  computed: {
    user () {
      if (this.$symfony.auth && this.$symfony.auth !== 'anon.') {
        return this.$symfony.auth
      } else {
        return false
      }
    },
    Model () {
      return Cart
    },
    fetchUrl () {
      return '/user/paniers'
    },
    modelList() {
      return this.response?.cart || [];
    }
  },
  methods: {
    preQuery (query) {
      query.with(['user.ImageProfil', 'user.infoSociete', 'cartItems.annonces.cover'])
      if (this.user && this.user.id) {
        query.where('user_id', this.user.id)
      }
      // query.where((cart) => {
        // return cart.status === 'pending'
      // })
    }
  }
}
</script>

<template>
  <section class="cart-page-paniers">
    <div class="container ">
      <h2 class="main-title">
        {{ $t('cart.currents.title') }}
      </h2>
      <h6 class="sub-title">{{$t('cart.currents.subtitle.1')}}</h6>
      <div v-loading="isLoadingContent" class="cart-list-grid">
        <CartCard v-for="cart in modelList"  :cart="cart" :to="{ name: 'cart', params: { id: cart.id }}"  ></CartCard>
      </div>
    </div>
  </section>
</template>

<style scoped>

</style>