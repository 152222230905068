<template>
  <div class="common-layout" id="website">
    <el-drawer v-model="drawer" :direction="'ltr'" :size="'90%'" :show-close="false">
      <template #header>
        <div class="drawer-header">
          <img :src="asset('img/new/logo-dark.png')" alt="logo-espace-loueur" class="top-logo">
          <span class="close-icon" @click="drawer = false"><span class="icon-kit">&#xe933</span></span>
        </div>
      </template>
      <div class="mobile-left-bar">
        <router-view name="LeftBar"></router-view>
      </div>
    </el-drawer>
    <router-view name="TopBar"></router-view>
    <router-view name="Page"></router-view>
    <router-view name="Footer"></router-view>
    <router-view name="BottomBar" @onClickMenu="onClickMenu"></router-view>

  </div>
</template>

<script>
import TenantLeftMenu from "../components/TenantLeftMenu.vue"
import LeftBar from "../components/leftBar.vue"

export default {
  name: "BaseDefaultLayout",
  components: {
    TenantLeftMenu,
    LeftBar
  },
  data() {
    return {
      drawer: false
    }
  },
  computed: {
    // La fonction getAppropriateMenu a été supprimée
  },
  mounted() {
    // Les logs de débogage ont été supprimés
  },
  beforeRouteUpdate(to, from, next) {
    if (this.drawer) {
      this.drawer = false
    }

    next();
  },
  methods: {
    onItemClick () {
      // Le log de débogage a été supprimé
    },
    onClickMenu () {
      // Le log de débogage a été supprimé
      this.drawer = !this.drawer
    }
  }
}
</script>

<style>
  #website {
    margin-top: 70px;
  }
</style>