<template>
  <div class="common-layout">
    <el-container style="height: 100vh; position: relative;">
      <el-aside width="200px" class="inner_left_drawer hidden-sm-and-down hidden-xs-only" style="--el-aside-width: 250px;">
        <img :src="asset('img/espace-loueur/logo.png')" alt="logo-espace-loueur" class="top-logo">
        <router-view name="TopBar" ></router-view>
      </el-aside>
      <el-container class="rental-space-body">
        <router-view name="Page"></router-view>
      </el-container>
      <router-view name="BottomBar" @onClickMenu="onClickMenu"></router-view>
    </el-container>
    <el-drawer v-model="drawer" :direction="'ltr'" :size="'90%'" :show-close="false">
      <template #header>
        <div class="drawer-header">
          <img :src="asset('img/espace-loueur/logo.png')" alt="logo-espace-loueur" class="top-logo">
          <span class="close-icon" @click="drawer = false"><span class="icon-kit">&#xe933</span></span>
        </div>
      </template>
      <div class="mobile-left-bar">
        <router-view name="TopBar"></router-view>
      </div>
    </el-drawer>
  </div>
</template>

<script>

import LeftBar from "~/components/leftBar.vue";

export default {
  name: "BaseShopLayout",
  components: {LeftBar},
  beforeRouteUpdate(to, from, next) {
    // Logique à exécuter avant la mise à jour de la route
    if (this.drawer) {
      this.drawer = false
    }

    // N'oublie pas d'appeler next() pour confirmer la navigation
    next();
  },
  data () {
    return {
      drawer: false
    }
  },
  methods: {
    onClickMenu () {
      this.drawer = !this.drawer
    },
    onClickMenuItem () {
      this.drawer = false
    }
  }
}
</script>

<style scoped>

</style>