<script>
import BankValidationBehavior from "~/modules/payment/mixins/BankValidationBehavior.js";

export default {
  name: "BankValidationComplete",
  mixins: [BankValidationBehavior],
  methods: {
    tableRowClassName({ rowIndex }) {
      return rowIndex % 2 === 0 ? 'even-row' : 'odd-row';
    },
    onRowClick () {
      console.log('boom')
    }
  }
}
</script>

<template>
  <div v-if="globalDocumentsStatus === 'VALIDATED'" class="alert alert-success mb-4" role="alert">
    <div class="alert-icon">
      <span class="fa fa-check-circle"></span>
    </div>
    <span v-html="'Félicitation, vos documents ont tous été validés par notre prestataire bancaire'"></span>
  </div>
  <el-card v-else class="p-0">
    <div class="table-filter-container">
      <h3 class="subTitle">{{ $t('shop.settings.bank-validation.steps.complete.title') }}</h3>
      <span class="card-description">
          {{ $t('shop.settings.bank-validation.steps.complete.description') }}
        </span>
    </div>
    <hr>
    <el-table :data="userDocuments" style="width: 100%; " :table-layout="'auto'" @row-click="onRowClick" :row-class-name="tableRowClassName">
      <el-table-column  label="Procédure">
        <template #default="{row}">
          <span v-if="row.document">{{ $t('mangopay.LegalDocuments.type.' + row.document) }}</span>
        </template>
      </el-table-column>
      <el-table-column  label="Progression">
        <template #default="{row}">
          <el-tag :type="row.labelClass">{{ row.labelStatus }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column>
        <template #default="{row}">
          <span>{{ $moment.unix(row.CreationDate).format("D MMMM Y") }}</span>
        </template>
      </el-table-column>
    </el-table>
  </el-card>
</template>

<style scoped>

</style>