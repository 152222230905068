<template>
  <div>
    <div class="auto-match-controls">
      <el-button 
        type="primary" 
        @click="startProcess" 
        :loading="isProcessing"
        :disabled="isProcessing">
        {{ buttonText }}
      </el-button>

      <div v-if="showProgress" class="progress-info">
        <div>{{ processedCount }} demandes traitées</div>
        <div v-if="currentBatch.length > 0" class="current-batch">
          Traitement en cours :
          <ul>
            <li v-for="request in currentBatch" :key="request.id">
              {{ request.marque }} {{ request.modele }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  data() {
    return {
      isProcessing: false,
      processedCount: 0,
      showProgress: false,
      currentBatch: [],
      currentDemandeIds: []
    }
  },
  methods: {
    async startProcess() {
      if (this.isProcessing) return;
      
      this.isProcessing = true;
      this.showProgress = true;
      
      try {
        // Étape 1: Récupérer les 10 prochaines demandes à traiter
        const demandeResponse = await axios.get('/admin/auto-match-models/next-demande');
        
        if (!demandeResponse.data.success || !demandeResponse.data.demandes.length) {
          this.$notify({
            title: 'Info',
            message: 'Aucune demande à traiter',
            type: 'info'
          });
          return;
        }

        const demandes = demandeResponse.data.demandes;
        this.currentBatch = demandes.map(d => ({
          id: d.id,
          marque: d.marque,
          modele: d.modele
        }));
        this.currentDemandeIds = demandes.map(d => d.id);

        // Étape 2: Traiter ce lot de demandes
        const matchResponse = await axios.post('/admin/auto-match-models/process', {
          demandeIds: this.currentDemandeIds
        });

        if (matchResponse.data.success) {
          this.processedCount += this.currentBatch.length;
          
          this.$emit('complete', {
            suggestions: matchResponse.data.results.suggestions || [],
            automaticMatches: matchResponse.data.results.automatic_matches || []
          });
          
          this.$notify({
            title: 'Succès',
            message: `${this.currentBatch.length} demandes traitées avec succès`,
            type: 'success'
          });
        } else {
          throw new Error(matchResponse.data.message || 'Erreur lors du traitement');
        }
      } catch (error) {
        console.error('Erreur détaillée:', error);
        this.$notify({
          title: 'Erreur',
          message: error.message || 'Erreur lors du traitement des demandes',
          type: 'error'
        });
      } finally {
        this.isProcessing = false;
        this.currentBatch = [];
        this.currentDemandeIds = [];
      }
    }
  },
  computed: {
    buttonText() {
      if (this.isProcessing) {
        return 'Traitement en cours...';
      }
      return 'Traiter les 10 prochaines demandes';
    }
  }
}
</script>

<style scoped>
.auto-match-controls {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.progress-info {
  margin-top: 0.5rem;
  font-size: 0.9rem;
  color: #606266;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.current-batch {
  font-style: italic;
  color: #909399;
}

.current-batch ul {
  margin: 0.5rem 0;
  padding-left: 1.5rem;
}

.current-batch li {
  margin: 0.25rem 0;
}
</style> 